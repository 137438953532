import React, { ReactElement, useMemo } from 'react';
import { HighlightedText } from '@sprinklr/shared-lib/components/higlightedText';
import { Box, Text } from 'theme-ui';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';
import TemplateRenderer from '../../components/tempateRenderer/TemplateRenderer';
import { RichText } from '@sprinklr/shared-lib';
import { SUPPORTED_CONTENT_LIST_ITEM_TYPES } from './constants';
import {
  ContainerPaddingValue,
  RichTextObject,
} from '@sprinklr/shared-lib/@types/entities';

// TODO: Remove this logic once contentList of type ContentfulPodcastV1, ContentfulCxStory etc. are moved into wrapper templates.
const transformContentListToTemplates = (
  contentList: {
    __typename: string;
  }[],
) => {
  if (!contentList || contentList.length <= 0) return [];

  const isContentListToBeConvertedIntoTemplate = contentList.some(item =>
    SUPPORTED_CONTENT_LIST_ITEM_TYPES.includes(item.__typename),
  );

  if (isContentListToBeConvertedIntoTemplate) {
    const firstContentItemTemplateType = contentList[0].__typename;

    const isContentListHomogeneous = contentList.every(
      item => item.__typename === firstContentItemTemplateType,
    );

    return isContentListHomogeneous
      ? [{ __typename: firstContentItemTemplateType, list: contentList }]
      : [];
  }

  return contentList;
};

const SprinklrCxWiseSectionTypeA = (props: {
  title: string;
  description: RichTextObject;
  topMargin: ContainerPaddingValue;
  bottomMargin: ContainerPaddingValue;
  backgroundColor: 'White' | 'Dark';
  contentList: any[];
}): ReactElement => {
  const {
    title,
    description,
    topMargin,
    bottomMargin,
    backgroundColor = 'White',
    contentList = [],
  } = props;
  const padding = {
    top: topMargin ?? 'SMALL',
    bottom: bottomMargin ?? 'SMALL',
  };
  const [pt, pb] = useContainerPadding(padding);

  const background =
    backgroundColor === 'White'
      ? '#fff'
      : 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(102.71deg, #0A0A14 0.13%, #052A3F 74.44%)';

  const textColor = backgroundColor === 'White' ? '#000' : '#fff';

  const templates = useMemo(
    () => transformContentListToTemplates(contentList),
    [contentList],
  );

  return (
    <Box sx={{ pt, pb, background }}>
      <HighlightedText
        sx={{
          maxWidth: '1000px',
          marginX: 'auto',
          textAlign: 'center',
          fontSize: ['36px', '40px', '48px'],
          lineHeight: ['44px', '50px', '58px'],
          fontWeight: 800,
          marginBottom: '0px',
          color: textColor,
          textTransform: 'uppercase',
        }}
        as="h2"
      >
        {title}
      </HighlightedText>
      <Text
        sx={{
          textAlign: 'center',
          marginInline: '84px',
          p: {
            fontWeight: 400,
            color: textColor,
          },
        }}
        as="div"
      >
        <RichText richTextObject={description} />
      </Text>

      <TemplateRenderer templates={templates} />
    </Box>
  );
};

export default SprinklrCxWiseSectionTypeA;
